import { Box, Divider, Typography } from "@mui/material";
import Logo from "./logo.png";
import { Link } from "react-router-dom";
import { WhatsApp } from "@mui/icons-material";

export const Tours = () => {
  return (
    <>
      <Box
        component="nav"
        sx={{ background: "black", height: 60 }}
        pl={{
          xs: 1,
          sm: 0,
        }}
        pr={{
          xs: 1,
          sm: 0,
        }}
      >
        <Box
          sx={{
            maxWidth: { xs: "100%", md: "1200px" },
            margin: "0 auto",
          }}
        >
          <Link to="/">
            <img src={Logo} height="60" />
          </Link>
        </Box>
      </Box>
      <Box
        component="section"
        p={{ xs: 4, sm: 8 }}
        pt={{ xs: 12, sm: 8 }}
        pb={{ xs: 12, sm: 8 }}
        sx={{
          background: "black",
          color: "white",
          lineHeight: "1.5",
          fontWeight: "light",
          width: "100%",
        }}
      >
        <Box
          sx={{
            maxWidth: { xs: "100%", md: "1200px" },
            margin: "0 auto",
          }}
        >
          <Typography variant="h4" component="h2">
            Tours 360
          </Typography>
          <Divider
            sx={{ mt: 3, mb: 3, background: "white", height: 2, width: "50px" }}
          />
          <Box>
            <Typography variant="body">
              Ofrecemos <strong>tours virtuales 360</strong> para tus negocios.
            </Typography>
          </Box>
        </Box>
      </Box>
      <iframe
        width="100%"
        height="600"
        src="https://kuula.co/share/collection/7FJmZ?logo=1&info=0&fs=1&vr=1&sd=1&thumbs=1&inst=es"
        frameBorder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowFullScreen
      />
      <footer>
        <Box
          display="flex"
          justifyContent="center"
          pt={6}
          pb={6}
          sx={{ background: "#10FFD6", color: "black" }}
        >
          <Typography variant="body2">Powered by Digidrone</Typography>
        </Box>
      </footer>
      <Box
        position="fixed"
        sx={{
          background: "#26d367",
          bottom: "20px",
          right: "20px",
          width: "60px",
          height: "60px",
          borderRadius: "60px",
          zIndex: 4,
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="white"
        component={"a"}
        href={"https://api.whatsapp.com/send?phone=5491134411604"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <WhatsApp fontSize="large" />
      </Box>
    </>
  );
};
