import {
  Avatar,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import Logo from "./logo.png";
import { EmailOutlined, Instagram, WhatsApp } from "@mui/icons-material";
import { Link } from "react-router-dom";

export const Home = () => {
  return (
    <>
      <Box position="relative" style={{ height: "100vh" }}>
        <video
          autoPlay
          loop
          muted
          playsinline
          style={{
            objectFit: "cover",
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: 1,
          }}
        >
          <source src="bga.mp4" type="video/mp4" />
        </video>
        <Box
          sx={{
            background: "rgba(0, 0, 0, 0.5)",
            width: "100%",
            height: { xs: "100%" },
            position: "absolute",
            zIndex: 1,
          }}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <img src={Logo} height="200" />
        </Box>
      </Box>

      <Box
        display={{
          xs: "block",
          sm: "flex",
        }}
        justifyContent="space-between"
        flexDirection={{
          xs: "column-reverse",
          sm: "row-reverse",
        }}
      >
        <Box
          component="section"
          p={{ xs: 4, sm: 8 }}
          pt={{ xs: 12, sm: 8 }}
          pb={{ xs: 12, sm: 8 }}
          sx={{
            background: "black",
            color: "white",
            lineHeight: "1.5",
            fontWeight: "light",
            width: "100%",
          }}
        >
          <Typography variant="h4" component="h2">
            Nuestros servicios
          </Typography>
          <Divider
            sx={{ mt: 3, mb: 3, background: "white", height: 2, width: "50px" }}
          />
          <Box>
            <Typography variant="body">
              <strong>Filmación y fotografía aérea</strong> en eventos sociales
              y empresariales, sector inmobiliario y de construcción, turismo,
              publicidad, hotelería, deportes, entre otros.
              <br />
              <br />
              Te ofrecemos videos creativos y de la más alta calidad para que
              destaques en tu web y redes sociales.
            </Typography>
            <Box mt={5}>
              <a href="servicios-digidrone.pdf" target="_blank">
                <Button fullWidth variant="contained">
                  Ver nuestros servicios
                </Button>
              </a>
              <Link to="/tours">
                <Button fullWidth variant="outlined" sx={{ mt: 2 }}>
                  TOURS 360
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>

        <Box
          component="section"
          p={{ xs: 4, sm: 8 }}
          pt={{ xs: 12, sm: 8 }}
          pb={{ xs: 12, sm: 8 }}
          sx={{
            color: "black",
            lineHeight: "1.5",
            fontWeight: "light",
            width: "100%",
          }}
        >
          <Typography variant="h4" component="h2">
            Envianos tu consulta
          </Typography>
          <Divider
            sx={{
              mt: 3,
              mb: 3,
              background: "black",
              height: 2,
              width: "50px",
            }}
          />
          <Typography variant="body">
            Estos son nuestros canales de contacto.
          </Typography>
          <List sx={{ mt: 1 }}>
            <ListItem sx={{ padding: "6px 0" }}>
              <ListItemAvatar>
                <Avatar>
                  <EmailOutlined />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Email" secondary="digidronex@gmail.com" />
            </ListItem>
            <ListItem sx={{ padding: "6px 0" }}>
              <ListItemAvatar>
                <Avatar>
                  <Instagram />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Instagram" secondary="digidronex" />
            </ListItem>
            <ListItem sx={{ padding: "6px 0" }}>
              <ListItemAvatar>
                <Avatar>
                  <WhatsApp />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="WhastApp" secondary="+54 9 11 3441-1604" />
            </ListItem>
          </List>
        </Box>
      </Box>
      <footer>
        <Box
          display="flex"
          justifyContent="center"
          pt={6}
          pb={6}
          sx={{ background: "#10FFD6", color: "black" }}
        >
          <Typography variant="body2">Powered by Digidrone</Typography>
        </Box>
      </footer>
      <Box
        position="fixed"
        sx={{
          background: "#26d367",
          bottom: "20px",
          right: "20px",
          width: "60px",
          height: "60px",
          borderRadius: "60px",
          zIndex: 4,
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="white"
        component={"a"}
        href={"https://api.whatsapp.com/send?phone=5491134411604"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <WhatsApp fontSize="large" />
      </Box>
    </>
  );
};
